<template>
    <div id="app">
        <router-view name="main"/>
    </div>
</template>
<script>
    export default {
        name: 'app',
        components:{
        }
    }
</script>


